<template>
  <vf-accordion v-show="auth.loggedIn && details" v-model="opened">
    <template #toggle="{ expanded }">
      <div class="flex items-center py-3 between" data-test-id="vf-accordion-toggle">
        <div>
          <div class="text-md">
            {{ greeting }}
          </div>
          <div class="mt-1 truncate fw-bold">
            {{ replaceAll($t.loyaltyRewardsDetails.rewardAmount, { reward: loyalty.currentReward }) }}
          </div>
        </div>
        <vf-icon name="chevron" size="lg" :dir="expanded ? 'up' : 'down'" />
      </div>
    </template>
    <loyalty-rewards-details v-if="loyalty.vouchers" :vouchers="loyalty.vouchers" />
  </vf-accordion>
</template>

<script setup lang="ts">
const { $t } = useNuxtApp()
const { details } = storeToRefs(useProfileStore())
const auth = useAuthStore()
const loyalty = useLoyaltyStore()

const greeting = computed(() =>
  details.value?.firstName
    ? replaceAll($t.loyaltyRewardsDetails.greetingWithName, { name: details.value.firstName })
    : $t.loyaltyRewardsDetails.greeting)

if (auth.loggedIn && !loyalty?.vouchers) loyalty.get()

const opened = ref(true)
</script>
