<template>
  <div :class="{ 'sticky top-0 z-above-header': isStickyPromoBarEnabled && isStickyHeaderEnabled && showHeader }">
    <client-only>
      <lazy-cms-section
        v-if="!disablePromoBarOnRoutes.includes(route.path) && megaMenu?.promoBar"
        :section="promoBarSection"
        data-scroll-el="promo-bar"
      />
    </client-only>
  </div>
  <!-- Workaround for a stuck state issue on mobile -->
  <div v-if="transparent" class="relative z-1 lg:hidden" style="height: 1px; background: #000000cc" />
  <base-sticky v-slot="{ isStuck }" :margin="offset" name="headerMain">
    <header
      :key="renderIdx"
      ref="headerRef"
      :class="[
        brandClasses.header,
        isStickyHeaderEnabled && !showHeader ? 'z-0 op-0' : 'z-header',
        { sticky: isStickyHeaderEnabled },
      ]"
      :style="`top: ${offset}px`"
      data-scroll-el="header"
    >
      <div
        class="duration"
        :class="{
          'bg-white': (isStuck && y > 0) || menuOpened || !transparent || suggestionsOpened,
          'shadow-md <lg:pt-px': isStickyHeaderEnabled && showHeader && (isStuck && y > 0) && !isSomethingElseSticky,
          'shadow-none absolute inset-x-0': transparent && !(isStuck && y > 0),
          '<lg:shadow-md': !transparent && !isStickyHeaderEnabled,
        }"
      >
        <!-- Fader of the transparent header -->
        <div
          v-if="transparent"
          class="absolute-0 -z-1"
          style="background: linear-gradient(to top, transparent, rgba(0,0,0,0.8));"
        />
        <vf-utility-navigation
          v-if="links && !customs"
          ref="utilityNavigationRef"
          class="relative min-h-8 flex items-center justify-end gap-6 container <lg:hidden"
          :class="[brandClasses.utilityNavigation, { 'c-white': transparent && !menuOpened && !suggestionsOpened }]"
          :links
        />
        <div
          ref="primaryNavigationRef"
          class="relative flex items-center py-4 container between duration lg:py-2"
          :class="[
            brandClasses.primaryNavigation,
            { 'c-white': transparent && !(isStuck && y > 0) && !menuOpened && !suggestionsOpened },
          ]"
        >
          <base-link to="/" class="shrink-0" :class="brandClasses.logoLink">
            <slot>
              <vf-logo
                :invert="transparent && !(isStuck && y > 0) && !menuOpened && !suggestionsOpened"
                :variant="logoVariant"
                :class="brandClasses.logo"
                class="w-a"
              />
            </slot>
          </base-link>
          <div
            class="flex grow justify-end lg:justify-between"
            :class="[brandClasses.primaryNavigationContainer, { '': !(isStuck && y > 0) }]"
          >
            <lazy-cms-section v-if="megaMenu" :section="megaMenuSection" />
            <div
              class="flex shrink-0 items-center gap-2 lg:self-center <lg:-mr-2"
              :class="brandClasses.iconWrapper"
            >
              <vf-search
                v-model:opened="suggestionsOpened"
                :class="{ '<lg:hidden': customs }"
                :transparent="transparent && !(isStuck && y > 0) && !menuOpened && !suggestionsOpened"
                @search="onSearch"
              />
              <base-link to="/cart" class="relative <lg:mx-2">
                <span class="sr-only">{{ $t.cart }}</span>
                <vf-icon :name="cartIcon" :size="cartIconSize" />
                <client-only>
                  <span
                    v-if="cart?.totalItems"
                    class="pointer-events-none absolute bottom-0 right-0 h-4 w-4 flex select-none center b-2 b-white rounded-full bg-brand-2 c-white"
                    style="font-size: .5rem;"
                  >
                    {{ cart.totalItems }}
                  </span>
                </client-only>
              </base-link>
              <vf-mega-menu-mobile v-if="megaMenu" :mega-menu="megaMenu" :utility-links="links as any" />
              <vf-button
                v-if="showClearSessionButton && buyInStore.storeInfo?.storeId"
                size="xs"
                variant="secondary"
                class="<md:w-full"
                @click="buyInStore.clearSession"
              >
                {{ $t.clearSession }}
              </vf-button>
            </div>
          </div>
        </div>
      </div>
    </header>
  </base-sticky>
  <lazy-cms-section
    v-if="!disableBenefitBarOnRoutes.includes(route.path) && megaMenu?.benefitBar"
    :section="benefitBarSection"
  />
</template>

<script lang="ts" setup>
import { HeaderContextKey } from './context'
import type { Section } from '#types/page'
import type { MenuItem } from '#types/components/cms/mega-menu'
import type { Message } from '#types/components/cms/promo-bar'

defineProps<{
  /**
   * Vans Customs uses a special type of main header layout
   */
  customs?: boolean
}>()

const route = useRoute()
const config = useAppConfig()
const { disableBenefitBarOnRoutes, disablePromoBarOnRoutes } = useFeatureFlags()
const transparentState = useState<boolean>('headerTransparent')
const transparent = computed(() => transparentState.value ?? route.meta.header?.transparent ?? config.components.vf.header.transparent)
const menuOpened = ref(false)
const suggestionsOpened = ref(false)
const { y } = useScroll(document)

provide(HeaderContextKey, {
  menuOpened,
  suggestionsOpened,
})

const {
  brandClasses,
  cartIcon,
  cartIconSize,
  isStickyPromoBarEnabled,
  logoVariant
} = config.components.vf.header
const { showClearSessionButton } = config.checkout.buyInStore
const buyInStore = useBuyInStoreStore()
const cart = useCartStore()
const router = useRouter()

const [megaMenu, { data: links }] = await Promise.all([
  useCms().getMegaMenu().then((res) => res && ({
    ...res,
    promoBar: res.promoBar && {
      ...res.promoBar,
      messages: res.promoBar.messages as Message[]
    },
    children: res.children as MenuItem[]
  })),
  useApi().internal.getUtility()
])

const megaMenuSection = {
  name: 'mega-menu',
  items: megaMenu ? [megaMenu] : []
} as Section

const promoBarSection: Section = {
  name: 'promo-bar',
  items: [{ ...megaMenu?.promoBar, type: 'VfCanvasPromoBar', id: 'promo-bar' }]
}

const benefitBarSection: Section = {
  name: 'benefit-bar',
  items: megaMenu?.benefitBar?.length
    ? [{ ...megaMenu.benefitBar[0], isFullWidth: true }]
    : []
}

const headerRef = useState<HTMLElement>('headerRef')
const primaryNavigationRef = useState<HTMLDivElement>('primaryNavigation')
const utilityNavigationRef = useState<HTMLElement>('utilityNavigation')
const { height: utilityNavigationHeight } = useElementBounding(utilityNavigationRef)
const { height: promoBarHeight } = useElementBounding(useState('promoBarRef'))
const offset = computed(
  () => (utilityNavigationHeight.value - (isStickyPromoBarEnabled ? promoBarHeight.value : 0)) * -1
)

const onSearch = (query: string) => router.push(useLocalisedRoute(`/search?q=${query}`))
const stickyState = useSticky()
const { pageScrolledUp, isStickyHeaderEnabled } = useStickyHeader()
const isSomethingElseSticky = computed(() =>
  ['headerPLP', 'productDetailsCta'].find((element) => !!stickyState.value[element]))

// delay showing header if something unsticks as hiding header unsticks everything for one tick
const delayedIsSomethingElseSticky = ref(false)

// workaround for the transparent header that sometimes is returning non-transparent even if all the variables are set to be transparent
const renderIdx = ref(0)

onMounted(async () => {
  await nextTick()
  renderIdx.value += 1
})

watch (isSomethingElseSticky, async () => {
  await nextTick()
  delayedIsSomethingElseSticky.value = !!isSomethingElseSticky.value
})

const showHeader = computed(() =>
  !delayedIsSomethingElseSticky.value || (isSomethingElseSticky.value && pageScrolledUp.value) || false)
</script>
