<template>
  <div
    ref="wrapperRef"
    class="h-full flex items-center"
    :class="showMobileForm ? brandClasses.mobileWrapper : ''"
    @keydown.esc="close"
  >
    <!-- Mobile search icon -->
    <base-lazy-hydrate when="interaction" :interaction="['click', 'focus']">
      <icon-search
        v-model="showMobileForm"
        type="button"
        :aria-expanded="showMobileForm"
        aria-controls="vf-search-form"
        class="mx-2 cursor-pointer lg:hidden"
        :size="mobileNavIconSize"
        @click="toggleSearch"
      >
        <span class="sr-only">{{ $t.searchIconLabel }}</span>
      </icon-search>
    </base-lazy-hydrate>
    <!-- Backdrop -->
    <div
      class="fixed-0 h-full bg-black/75"
      :class="{ '<lg:hidden': !showMobileForm, 'lg:hidden': !showSuggestions }"
      :style="{ top: `${height + top || 0}px` }"
      @click.self="close"
    />
    <!-- Search form -->
    <div
      id="vf-search-form"
            :class="showMobileForm ? '<lg:absolute-0 <lg:mt-14' : '<lg:hidden'"
    >
      <form
        ref="formRef"
        role="search"
        class="flex <lg:absolute <lg:inset-x-0 <lg:px-4 <lg:py-2"
        :class="[brandClasses.form, transparent ? 'b-white' : 'b-grey-30']"
        @submit.prevent="submit"
      >
        <vf-input class="h-10 grow rounded-r-0" :class="[brandClasses.input, transparent ? brandClasses.inputTransparent : '']">
          <span id="vf-search-form-input" class="sr-only">
            {{ $t.searchLabel }}
          </span>
          <template #input>
            <base-input
              ref="inputRef"
              v-model="searchValue"
              type="text"
              :placeholder="$t.searchPlaceholder"
              aria-labelledby="vf-search-form-input"
              class="h-full"
              @click="handleOpenSuggestions"
              @keyup.tab="handleOpenSuggestions"
            />
          </template>
          <template #end>
            <base-button v-if="searchValue" :aria-label="$t.searchClearLabel" @click="clear(true)">
              <vf-icon name="clear" size="md" />
            </base-button>
            <template v-else-if="showImageSearch && $feature.showShopSimilarOnSearch">
              <vf-search-image-tooltip class="<lg:hidden" @click="initSyte" />
              <base-button
                v-if="syteReady"
                class="--syte-start-camera-upload lg:hidden"
                :aria-label="$t.searchImageLabel"
                data-camera-button-placement="search-bar"
              >
                <vf-icon name="objective" size="md" />
              </base-button>
            </template>
          </template>
        </vf-input>
        <base-button
          type="submit"
          :aria-label="$t.searchButtonLabel"
          class="p-1"
          :class="[brandClasses.button, transparent ? brandClasses.buttonTransparent : '']"
          style="margin-left: -1px"
        >
          <vf-icon name="search" :size="iconSize" />
        </base-button>
        <!-- Search suggestion box -->
        <transition
          enter-from-class="op-0"
          enter-active-class="transform ease-out"
          leave-active-class="transform ease-in"
          leave-to-class="op-0"
        >
          <div
            v-if="showSuggestions"
            ref="suggestionsRef"
            class="w-full bg-white c-grey-10 duration lg:mt-2 lg:w-208"
            :class="brandClasses.suggestions.wrapper"
            :style="{
              'min-height': '20.5rem',
              'position': strategy,
              'top': `${y ?? 0}px`,
              'left': `${x ?? 0}px`,
            }"
          >
            <section
              :aria-label="$t.searchResults"
              class="f-col gap-6 py-4 md:flex-row md:px-6 md:py-8 lg:px-8 "
            >
              <div class="space-y-6 ">
                <h2 class="mb-6 <md:px-4 " :class="brandClasses.suggestions.title">
                  {{ $t.featuredResults }}
                </h2>
                <ul class="md:grid md:cols-2 md:w-134 md:gap-x-6 md:gap-y-8 <md:w-full <md:flex <md:gap-x-4 <md:overflow-x-auto <md:px-4">
                  <li
                    v-for="{ id, name, images, currency, price, url } in suggestions?.products"
                    :key="id"
                    class="relative w-full flex items-center gap-2 <md:w-33 <md:flex-col"
                  >
                    <base-picture
                      v-if="images"
                      :src="images"
                      :alt="name"
                      :height="{ sm: 132, md: 88, lg: 88 }"
                      :width="{ sm: 132, md: 88, lg: 88 }"
                      class="block min-w-22 <md:min-w-33"
                    />
                    <div class="grow text-sm">
                      <p class="line-clamp-2 mb-2 fw-medium ">
                        {{ name }}
                      </p>
                      <product-pricing :price :currency />
                    </div>
                    <base-link
                      :to="{
                        path: url,
                        state: { suggestedPhrase: searchValue },
                      }"
                      :aria-label="name"
                      class="absolute-0"
                      @click="reset"
                    />
                  </li>
                </ul>
              </div>
              <div class="<md:order-first <md:px-4 space-y-4">
                <h2 :class="brandClasses.suggestions.title">
                  {{ $t.topSearches }}
                </h2>
                <ul class="text-sm space-y-2 ">
                  <li v-for="{ label, highlightedLabel } in suggestions?.terms" :key="label">
                    <!-- eslint-disable-next-line vue/no-v-html -->
                    <base-link
                      :to="{
                        path: `/search`,
                        query: { q: label },
                        state: { suggestedPhrase: label },
                      }"
                                            :aria-label="label"
                      @click="reset"
                    >
                      <span v-html="highlightedLabel" />
                    </base-link>
                  </li>
                </ul>
              </div>
            </section>
          </div>
        </transition>
      </form>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { autoUpdate, shift, useFloating } from '@floating-ui/vue'
import type { MaybeElement } from '@vueuse/core'
import { useFocusTrap } from '@vueuse/integrations/useFocusTrap'
import type { BaseInput as Input } from '#components'
import type { AutoSuggestData } from '#root/api/clients/product/data-contracts'

defineProps<{
  transparent?: boolean
}>()

const emit = defineEmits<{
  /**
   * Emits when search is clicked
   */
  'search': [query: string]
  /**
   * Emits when image-search is clicked
   */
  'image-search': []
  /*
  * Emits when suggestions appear
  */
  'suggestions-shown': []
  'suggestions-hidden': []
}>()

const opened = defineModel('opened', { default: false })

const {
  brandClasses,
  iconSize,
  lockBodyScroll,
  maxTopSearches = 7,
  mobileNavIconSize,
  searchThreshold = 0,
  showImageSearch
} = useAppConfig().components.vf.search
const { lock, unlock } = useBodyScroll()
const { height, top } = useElementBounding(useState<MaybeElement>('primaryNavigation'))
const { $feature, $viewport } = useNuxtApp()
const router = useRouter()
const { init: initSyte, ready: syteReady } = useSyte()

const wrapperRef = ref<HTMLDivElement>()
const formRef = ref<HTMLFormElement>()
const inputRef = ref<InstanceType<typeof Input>>()
const suggestionsRef = ref<HTMLDivElement>()

const searchValue = ref('')
const showMobileForm = ref(false)
const showSuggestions = ref(false)
const suggestionsQuery = reactive({ q: searchValue.value })
let abortSuggestions = false

const clear = (resetFocus = false) => {
  searchValue.value = ''
  if (resetFocus) inputRef.value?.$el.focus()
}

const close = () => {
  showMobileForm.value = false
  showSuggestions.value = false
}

const loadSyteOnMobile = () => {
  if ($feature.showShopSimilarOnSearch) initSyte()
}

const toggleSearch = () => {
  showMobileForm.value = !showMobileForm.value
  if (showMobileForm.value) loadSyteOnMobile()
}

const reset = () => {
  close()
  clear()
}

const { activate, deactivate } = useFocusTrap(wrapperRef, {
  checkCanFocusTrap: () => wait(250),
  clickOutsideDeactivates: true,
  onActivate: () => nextTick(() => inputRef.value?.$el.focus()),
  onDeactivate: close
})

const { x, y, strategy } = useFloating(formRef, suggestionsRef, {
  placement: 'bottom-end',
  whileElementsMounted: autoUpdate,
  middleware: [shift()]
})

const suggestionsAvailable = ({ products, terms }: AutoSuggestData) =>
  !!products?.length || !!terms?.filter(({ label }) => label !== suggestionsQuery.q).length

const { data: suggestions, pending: suggestionsPending } = useApi().products.autoSuggest(suggestionsQuery, {
  immediate: false,
  server: false,
  transform({ products, terms }) {
    const termRegExp = new RegExp(`(${searchValue.value.trim()})`, 'gi')
    return {
      products: products?.slice(0, 4),
      terms: terms?.slice(0, Math.min(maxTopSearches, 7)).map(({ label }) => ({
        label,
        highlightedLabel: label.replace(termRegExp, '<span class="fw-medium">$1</span>')
      }))
    }
  },
  onResponse({ response: { _data } }) {
    showSuggestions.value = suggestionsAvailable(_data) && !abortSuggestions
  }
})

const handleOpenSuggestions = () => {
  if (searchValue.value.length >= searchThreshold && suggestionsAvailable(suggestions.value!))
    showSuggestions.value = true
}

const submit = () => {
  if (searchValue.value.trim()) {
    abortSuggestions = suggestionsPending.value
    emit('search', searchValue.value.trim())
    reset()
  }
}

const onInput = useDebounceFn(() => {
  // hide suggestions if input length is below a threshold
  if (searchValue.value.length < searchThreshold) {
    showSuggestions.value = false
    return
  }

  // show previously fetched suggestions if the same search is re-entered
  if (searchValue.value === suggestionsQuery.q && !!suggestions) {
    showSuggestions.value = true
    return
  }
  // reset abort boolean when making a new search
  abortSuggestions = false
  suggestionsQuery.q = searchValue.value

  if (!searchValue.value && $viewport.md) close()
}, 100)

watch([showMobileForm, showSuggestions], () => {
  if (showMobileForm.value || showSuggestions.value) {
    activate()
    opened.value = true
    if (lockBodyScroll) lock()
    handleOpenSuggestions()
  }
  else {
    deactivate()
    opened.value = false
    if (lockBodyScroll) unlock()
  }
})

watch(searchValue, onInput)

// close suggestions and clear search if route changes
router.beforeEach(reset)
</script>
